/**
 * External dependencies
 */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

/**
 * Internal dependencies
 */
import Deadline from '../Deadline';

dayjs.extend(advancedFormat);

const FormIntro = () => {
	const deadline = process.env.REACT_APP_DEADLINE;
	const formattedDeadline = dayjs(deadline).format('YYYY MMMM Do');

	return (
		<div className="form-intro">
			<h2 className="font-bold text-3xl text-midnight-blue mb-2">
				Congratulations!
			</h2>

			<p className="text-black mb-10">
				Please review your information regarding your 2024 win. This is
				how it will appear in the book. Remember to click “save” at the
				bottom of the page once you’ve entered thoughts on your success.
			</p>

			<Deadline>
				Deadline to submit: <strong>{formattedDeadline}</strong>
			</Deadline>
		</div>
	);
};

export default FormIntro;
