/**
 * External dependencies
 */
import { createClient } from '@supabase/supabase-js';

export const SUPABASE_CLIENT = createClient(
	process.env.REACT_APP_SUPABASE_URL,
	process.env.REACT_APP_SUPABASE_ANON_KEY
);

export const QUESTION =
	'What factors do you believe contributed to your success in 2024?';
