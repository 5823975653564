/**
 * External dependencies
 */
import { Link } from 'react-router-dom';

const Masthead = () => {
	return (
		<div className="max-w-7xl mx-auto mb-10">
			<Link to="/">
				<img src="/masthead-2024.jpg" alt="" loading="lazy" />
			</Link>
		</div>
	);
};

export default Masthead;
