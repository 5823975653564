/**
 * External dependencies
 */
import { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { QUESTION } from '../../constants';
import FormIntro from '../FormIntro';
import Loading from '../Loading';
import PhotoGuidelines from '../PhotoGuidelines';
import AppContext from '../AppProvider/AppContext';
import SampleQuote from '../SampleQuote';
import Upload from '../Upload';
import UploadedPicture from '../UploadedPicture';
import Error from '../Error';

const Form = () => {
	const { session, supabaseClient } = useContext(AppContext);
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [quote, setQuote] = useState('');
	const [picture, setPicture] = useState('');
	const [profile, setProfile] = useState(null);
	const [formError, setFormError] = useState(null);

	const handleOnChangeQuote = useCallback(
		(event) => {
			setQuote(event.target.value);
		},
		[setQuote]
	);

	const handleOnSubmit = useCallback(
		async (event) => {
			event.preventDefault();

			if (!quote.trim().length) {
				setFormError('Please enter your quote.');
				return;
			}

			if (!picture.trim().length) {
				setFormError('Please upload your photo.');
				return;
			}

			const { data, error } = await supabaseClient
				.from('profiles')
				.upsert({
					id: user.id,
					first_name: user.user_metadata.first_name,
					last_name: user.user_metadata.last_name,
					completed: true,
					updated_at: new Date(),
					quote,
					photo_name: picture,
				})
				.select();

			if (data && !error) {
				navigate('/thank-you', { preventScrollReset: false });
			}
		},
		[picture, quote, user, navigate, supabaseClient]
	);

	const handleCompleteSuccessful = useCallback((data) => {
		setPicture(data?.[0]?.meta?.objectName);
	}, []);

	useEffect(() => {
		async function getUser() {
			if (session) {
				const supabaseUser = session.user;
				setUser(supabaseUser);

				const { data, error } = await supabaseClient
					.from('profiles')
					.select()
					.eq('id', supabaseUser?.id);

				if (!error && data) {
					setPicture(data?.[0]?.photo_name);
					setQuote(data?.[0]?.quote || '');
					setProfile(data?.[0]);
				}
			}
		}

		getUser();
	}, [session, supabaseClient]);

	if (user?.role !== 'authenticated') {
		return <Loading count={12} />;
	}

	return (
		<>
			<FormIntro />

			{!profile && <Loading />}

			{profile && (
				<form onSubmit={handleOnSubmit}>
					<div className="form">
						<ul>
							<li>
								<b>Your name will appear in the book as:</b>{' '}
								<span className="text-science-blue font-bold uppercase">
									{user?.user_metadata?.first_name}{' '}
									{user?.user_metadata?.last_name}
								</span>
							</li>

							<li className="mt-5">
								<b>
									Based on 2024 alignment your name will
									appear under:
								</b>{' '}
								<span className="text-science-blue font-bold">
									{profile?.group}
								</span>
							</li>

							<li className="mt-5">
								<b>You&apos;ve had</b>{' '}
								<span className="text-science-blue font-bold">
									{profile?.wins}
								</span>{' '}
								<b>COE National Wins</b>
							</li>

							<li className="mt-5">
								<p className="text-science-blue mb-5 pb-10 border-b border-blue-haze">
									If any of the above information is
									incorrect, please{' '}
									<a
										href="mailto:info@nncoewinners.com"
										className="font-bold hover:text-cerulean focus:text-cerulean"
									>
										click here to email the correct
										information
									</a>
									.
								</p>
							</li>

							<li className="mb-5 pb-10 border-b border-blue-haze mt-10">
								<p className="mb-5 font-bold">{QUESTION}</p>

								<p className="text-pale-sky mb-2 text-base">
									Space is limited for your quote. Do not
									exceed 460 characters (characters including
									words, spaces, and punctuation).{' '}
									<SampleQuote />
								</p>
								<p className="mb-2 text-base font-bold">
									Please provide your answer/quote to the
									above question in the below space:
								</p>
								<textarea
									className="text-science-blue mb-2 mt-2 text-base p-2 border border-blue-haze resize-none h-40 w-full"
									maxLength="460"
									onChange={handleOnChangeQuote}
									placeholder="Type in your quote here..."
									value={quote}
									required
								></textarea>
								<p className="text-right text-americano text-xs">
									Characters available: {460 - quote?.length}
								</p>
							</li>

							<li className="pb-10 border-b border-blue-haze mt-10">
								<h4 className="mb-5 text-2xl">Your photo</h4>

								<div className="flex flex-col gap-8 lg:flex-row w-full grow-0">
									<div className="lg:basis-2/3">
										<p className="text-base mb-5">
											On the right is the photo that will
											be used in the book. We prefer to
											use this photo since it is optimized
											for our production process of
											sizing, retouching, and customizing
											your background for the book.
										</p>

										<p className="text-base mb-2">
											If we are missing a photo of you, or
											if you feel strongly that a
											different photo should be used,
											please upload one following all of
											the guidelines outlined in the link
											below.
										</p>
										<p className="text-base font-bold mb-5">
											Click save when you are finished.
										</p>
										<p className="mb-5">
											<PhotoGuidelines />
										</p>
									</div>

									<div className="lg:basis-1/3 text-right shrink-0">
										<UploadedPicture
											picturePath={picture}
										/>
									</div>
								</div>

								<Upload
									user={user}
									onCompleteSuccessful={
										handleCompleteSuccessful
									}
								/>

								<p className="text-base mt-2">
									Max file size 10MB
								</p>
							</li>
						</ul>

						<div className="form-actions text-right mt-5">
							{formError && <Error message={formError} />}

							<input
								type="submit"
								value="Save"
								className="bg-mariner text-white hover:bg-science-blue focus:bg-science-blue rounded-md py-1.5 px-8 font-bold text-base inline-flex w-auto self-start"
							/>
						</div>
					</div>
				</form>
			)}
		</>
	);
};

export default Form;
